import React, { FC, useEffect, useState } from 'react'

import { Button, ModalContentStory } from '@juullabs/react-components'
import {
  ContentStory as ContentStoryType,
} from '@viewlio/types/src/contentful'
import cx from 'classnames'

import { NextGenImage } from 'components/common/NextGenImage'
import { getSplitTreatment } from 'lib/juulio/api'
import { segmentTrack } from 'lib/segment'
import { useModal } from 'utils/hooks/useModal'
import { markdown } from 'utils/markdown'

import styles from './ContentStory.module.scss'

const onSlideChange = (slideNumber: number) => {
  segmentTrack(`Content Story Slide Changed to ${slideNumber}`)
}

export const ContentStory: FC<ContentStoryType> = (entry) => {
  const { closeModal, openModal, isOpen } = useModal()
  const [renderContentStory, setRenderContentStory] = useState(false)

  const openContentStory = () => {
    segmentTrack('Content Story Opened')
    openModal()
  }

  const closeContentStory = () => {
    segmentTrack('Content Story Closed')
    closeModal()
  }

  useEffect(() => {
    const fetchSplitTreatment = async () => {
      const treatment = await getSplitTreatment('show_content_story')
      if (treatment?.features?.showContentStory === 'on') {
        setRenderContentStory(true)
        segmentTrack('Content Story CTA Shown')
      }
    }

    fetchSplitTreatment()
  }, [])

  return (
    renderContentStory && (
      <>
        <Button onClick={openContentStory} size='small' icon='icon/16x16/search'>
          {entry.ctaOpenStoryLabel}
        </Button>
        <div>
          <ModalContentStory
            closeButtonLabel='Close'
            isOpen={isOpen}
            defaultTheme={entry.theme}
            onRequestClose={closeContentStory}
            onSlideChange={onSlideChange}
            modalClassName={styles.swiperContainer}
            slideThemes={entry.slides.map((slide) => {
              // Slide theme must be opposite to font theme to be cohesive with
              // the way this attribute is described in contentful,
              // where fontTheme: 'light means light font and vice versa.
              if (slide.fontTheme === 'light') {
                return 'dark'
              } else if (slide.fontTheme === 'dark') {
                return 'light'
              }
            })}
          >
            {entry.slides.map((slide) => (
              <div key={slide.id}>
                <NextGenImage
                  wrapperClass={styles.slideImage}
                  desktopImage={slide.desktopImage}
                  mobileImage={slide.mobileImage}
                  maxWidths={{ desktop: 1360, mobile: 767 }}
                />
                {(slide.content || slide.heading) && (
                  <div className={cx(
                    styles.textBox,
                    {
                      [styles.light]: slide.fontTheme === 'light',
                    },
                  )}>
                    {slide.heading && (
                      <h3 className={styles.textBoxHeader}>
                        {slide.heading}
                      </h3>
                    )}
                    {slide.content && (
                      <div
                        className={styles.textBoxBody}
                        dangerouslySetInnerHTML={{
                          __html: markdown(slide.content),
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </ModalContentStory>
        </div>
      </>
    )
  )
}
