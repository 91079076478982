import { LineItem } from '@viewlio/types/src'
import { VariantOffering } from '@viewlio/types/src/ecommerce'

import { addLineItemsToCart } from 'components/shop/utils/addLineItemsToCart'

const getLineItems = (offerings: VariantOffering[]) => {
  const quantityMap: Record<number, LineItem> = {}
  offerings.forEach(offering => {
    quantityMap[offering.id] ||= {
      offeringId: offering.id,
      quantity: 0,
      sku: offering.variantSku,
    }
    quantityMap[offering.id].quantity++
  })
  return Object.values(quantityMap)
}

export const addOfferingsToCart = async (
  offerings: VariantOffering[],
  promoCode?: string,
) =>
  addLineItemsToCart(getLineItems(offerings), promoCode)
