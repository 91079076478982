import React, { FC } from 'react'

import { Button } from '@juullabs/react-components'
import { AddToCartButton as AddToCartButtonType } from '@viewlio/types/src/contentful'

import { useGlobalStore, selectCurrentProductCatalogue } from 'stores'
import { AnalyticsProps, withSegmentTracking } from 'utils/hocs/withSegmentTracking/withSegmentTracking'

import { addOfferingsToCart } from './addOfferingsToCart'

const findOfferings = (skus: string[], isCommitmentReward = false) => {
  const catalog = useGlobalStore(selectCurrentProductCatalogue)
  return catalog === null ? [] : skus.map(
    sku => catalog?.[sku]?.offerings.find(offering => (
      (isCommitmentReward ?
        offering.commitmentReward : !offering.commitmentReward)
      && !offering.subscriberExclusive
      && !offering.evergreen),
    ),
  ).filter(Boolean)
}

export type Props = AddToCartButtonType & AnalyticsProps & Partial<{
  buttonProps?: React.ComponentProps<typeof Button>
  className?: string
  onClick?: () => void
}>

export const AddToCartButton: FC<Props> = ({
  analyticsEventName,
  analyticsEventData = {},
  buttonStyle,
  className,
  buttonProps: externalButtonProps,
  onClick,
  promoCode,
  ...flattenedContentfulEntry
}) => {
  const offerings = findOfferings(
    flattenedContentfulEntry.skus,
    flattenedContentfulEntry.isCommitmentReward,
  )

  const onClickWithAddOffering = () => {
    onClick?.()
    addOfferingsToCart(offerings, promoCode)
  }

  const TrackedButton =
    withSegmentTracking<React.ComponentProps<typeof Button>>(Button)

  // Props coming from buttonStyle contentful prop will override whatever is
  // passed from outside in externalButtonProps
  const buttonStyleProps = Object.fromEntries(Object.entries({
    fill: buttonStyle === 'auto-ship' ? 'jade' : 'steel',
    theme: {
      primary: 'light',
      secondary: 'dark',
    }[buttonStyle],
    variant: buttonStyle === 'auto-ship' ? 'filled' : null,
  }).filter(([_key, value]) => value))

  return offerings.length ? (
    <TrackedButton
      className={className}
      fullWidth={true}
      {...externalButtonProps}
      {...buttonStyleProps}
      {...{
        analyticsEventData,
        analyticsEventName,
      }}
      onClick={onClickWithAddOffering}
    >
      {flattenedContentfulEntry.displayText}
    </TrackedButton>
  ) : null
}
