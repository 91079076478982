import React, { FC } from 'react'

import { Button } from '@juullabs/react-components'
import { CallToAction, FormattedEntry } from '@viewlio/types/src/contentful'
import cx from 'classnames'
import { camelize } from 'humps'

import { AddToCartButton } from 'components/contentful/AddToCartButton'
import { ContentStory } from 'components/contentful/ContentStory'
import { PageLink } from 'components/contentful/PageLink'

import styles from './EntryCallToAction.module.scss'

type CallToActionComponent = React.FC<{[key: string]: any}>
type CallToActionComponents = Record<string, CallToActionComponent>

const callToActionComponents: CallToActionComponents = {
  addToCartButton: AddToCartButton,
  contentStory: ContentStory,
  modal: () => null,
  pageLink: PageLink,
}

export type Props = FormattedEntry<CallToAction> & {
  analyticsEventData?: Record<string, any>
  buttonProps?: React.ComponentProps<typeof Button>
  className?: string
}

export const EntryCallToAction: FC<Props> = ({
  buttonProps,
  contentType,
  className,
  ...flattenedContentfulEntry
}) => {
  const Component =
    callToActionComponents[camelize(contentType)]

  return (
    <Component
      className={cx(styles.button, className)}
      buttonProps={buttonProps}
      {...flattenedContentfulEntry}
    />
  )
}
