import React from 'react'

import { LineItem } from '@viewlio/types/src'

import { showAddedToCartSnackbar } from 'components/common/AddedToCartSnackbar'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { bugsnagClient } from 'lib/bugsnag'
import { addToCart } from 'lib/juulio/api'
import { fetchCart, setCartItemCount, useCartStore } from 'stores/cartStore'
import { addFlashMessage, flushFlashMessages } from 'stores/uiStore'

export const addLineItemsToCart = async (
  newLineItems: LineItem[] | LineItem,
  promoCode?: string,
) => {
  flushFlashMessages()
  const { cartItemCount } = useCartStore.getState()

  const lineItemsArray
    = Array.isArray(newLineItems) ? newLineItems : [newLineItems]

  try {
    const response = await addToCart({ lineItems: lineItemsArray, promoCode })
    if ('itemCount' in response) {
      const {
        itemCount: newCartItemCount,
      } = response
      setCartItemCount(newCartItemCount)
      showAddedToCartSnackbar(newCartItemCount - cartItemCount)
      await fetchCart()
    } else {
      if (typeof response.json === 'undefined') {
        throw response
      }
      const { error } = await response.json()
      addFlashMessage({ content: error, type: 'error' })
    }
  } catch (error) {
    bugsnagClient?.notify?.(error)

    addFlashMessage({
      content: (<TranslatedMessage id='cart.unknown_error' />),
      type: 'error',
    })
  }
}
