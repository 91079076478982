import React, { useState } from 'react'

import { createGoPuffMultipassLink, GO_PUFF_URL } from 'components/common/Headers/urls'
import { fetchGopuffMultipass } from 'lib/juulio/api'
import { segmentTrack } from 'lib/segment'

export const useGopuffMultipass = (
  address: string,
  city: string,
  zipcode: string,
) => {
  const [processing, setProcessing] = useState(false)

  const onClick = async (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    setProcessing(true)
    e.preventDefault()

    if (zipcode) {
      segmentTrack('User has clicked GoPuff link', { zipcode })
    }

    // Open a blank popup immediately on user click
    const popup = window.open('', '_blank')
    if (!popup) {
      alert('Please allow popups for this website')
      return // Early exit if popup was blocked or failed to open
    }

    try {
      const {
        data: {
          attributes: {
            shopifyMultipass,
          },
        },
      } = await fetchGopuffMultipass({
        address,
        city,
        zipcode,
      })

      if (shopifyMultipass) {
        // Update popup location with the actual URL after fetching it
        popup.location.href = createGoPuffMultipassLink(shopifyMultipass)
      } else {
        throw new Error() // Throw error to handle in the catch block
      }
    } catch (_error) {
      // On error, direct to a default URL
      popup.location.href = GO_PUFF_URL
    } finally {
      setProcessing(false)
    }
  }

  return { onClick, processing }
}
