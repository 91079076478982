import React from 'react'

import { segmentTrack } from 'lib/segment'
import { useUiStore } from 'stores'
import { addSnackbarItem, setSnackbarAddedToCartCount, SnackbarPosition } from 'stores/uiStore'

import { AddedToCartSnackbar } from './AddedToCartSnackbar'

export const CART_SNACKBAR_IMPRESSION_WHITE = 'Add to Cart Snack Bar Impression - White'
export const CART_SNACKBAR_IMPRESSION_BLACK = 'Add to Cart Snack Bar Impression - Black'

export const showAddedToCartSnackbar = async (quantity: number) => {
  const {
    snackbar,
  } = useUiStore.getState()

  setSnackbarAddedToCartCount(quantity)
  segmentTrack(snackbar.theme === 'dark'
    ? CART_SNACKBAR_IMPRESSION_BLACK
    : CART_SNACKBAR_IMPRESSION_WHITE)

  addSnackbarItem({
    children: <AddedToCartSnackbar />,
    duration: 5000,
    id: 'add-to-cart-snackbar',
    onExit: () => setSnackbarAddedToCartCount(0),
    position: SnackbarPosition.AddedToCart,
    theme: snackbar.theme,
  })
}
