import React, { ComponentType, FC } from 'react'

import { segmentTrack } from 'lib/segment'

type ClickableComponent = {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
}

export type AnalyticsProps = {
  analyticsEventData?: Record<string, any>
  analyticsEventName?: string
}

export const withSegmentTracking = <T extends ClickableComponent>(
  Component: ComponentType<T> | null = null,
): FC<T> => {
  const NewComp: FC<T & AnalyticsProps> = ({
    analyticsEventData,
    analyticsEventName,
    onClick,
    ...props
  }) => {
    const handleClick = e => {
      if (analyticsEventName) {
        segmentTrack(analyticsEventName, analyticsEventData)
      }
      onClick?.(e)
    }
    return <Component {...props as T} onClick={handleClick} />
  }

  NewComp.displayName = `${Component?.displayName}WithSegmentTracking`
  return NewComp
}
