import { useState } from 'react'

export const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)

  // Recommend not using this and instead reach for the idempotent versions
  const toggleModal = () => setIsOpen(previousIsOpen => !previousIsOpen)
  // Idempotent versions
  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return {
    closeModal,
    isOpen,
    openModal,
    toggleModal,
  }
}
