import React, { PropsWithChildren } from 'react'

import cx from 'classnames'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { useUserDeliverabilityStore } from 'stores/userDeliverabilityStore/userDeliverabilityStore'
import { useGopuffMultipass } from 'utils/hooks/useGopuffMultipass'

import styles from './PageLink.module.scss'

type Props = {
  className?: string
  href: string
  target?: string
}

export const GoPuffLink: React.FC<PropsWithChildren<Props>> = ({
  className,
  children,
  target,
}) => {
  const {
    address,
    city,
    zipcode,
  } = useUserDeliverabilityStore()

  const {
    onClick,
  } = useGopuffMultipass(address, city, zipcode)

  return (
    <LocalizedLink
      className={cx(styles.link, className)}
      target={target}
      onClick={onClick}
    >
      {children}
    </LocalizedLink>
  )
}
