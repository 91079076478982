import React from 'react'

import { Button } from '@juullabs/react-components/src/components/index'
import classNames from 'classnames'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { TranslatedMessage } from 'components/common/TranslatedMessage'
import { useUiStore } from 'stores'

import styles from './AddedToCartSnackbar.module.scss'

export const AddedToCartSnackbar = () => {
  const { addedToCartCount, theme } = useUiStore(state => state.snackbar)
  const cartSnackbarTextClasses = classNames(
    styles.cartSnackbarText,
    {
      [styles.invertText]: theme === 'dark',
    },
  )

  const viewCartClasses = classNames(
    'button--tertiary',
    styles.viewCart,
    {
      [styles.invertText]: theme === 'dark',
    },
  )

  return (
    <div className={styles.cartSnackbar}>
      <TranslatedMessage
        id='cart.success_banner'
        className={cartSnackbarTextClasses}
        values={{ count: addedToCartCount }}
      />
      <LocalizedLink href='/cart'>
        <Button
          variant='text'
          className={viewCartClasses}
        >
          <TranslatedMessage id='cart.view_cart' className='text--button' />
        </Button>
      </LocalizedLink>
    </div>
  )
}
