import React from 'react'

import { Button } from '@juullabs/react-components'
import cx from 'classnames'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { AnalyticsProps, withSegmentTracking } from 'utils/hocs/withSegmentTracking/withSegmentTracking'

import { GoPuffLink } from './GoPuffLink'
import styles from './PageLink.module.scss'

type Props = AnalyticsProps & {
  buttonProps?: React.ComponentProps<typeof Button>
  buttonStyle?: 'auto-ship' | 'primary' | 'secondary'
  className?: string
  customTemplate?: 'gopuff'
  displayText: string
  targetBlank?: boolean
  url: string
}

export const PageLink: React.FC<Props> = ({
  buttonProps: externalButtonProps,
  className,
  buttonStyle,
  targetBlank,
  url,
  displayText,
  analyticsEventData,
  analyticsEventName,
  customTemplate,
}) => {
  const ButtonWithTracking =
    withSegmentTracking<React.ComponentProps<typeof Button>>(Button)

  // Props coming from buttonStyle contentful prop will override whatever is
  // passed from outside in externalButtonProps
  const buttonStyleProps = Object.fromEntries(Object.entries({
    fill: buttonStyle === 'auto-ship' ? 'jade' : 'steel',
    theme: {
      primary: 'light',
      secondary: 'dark',
    }[buttonStyle],
    variant: buttonStyle === 'auto-ship' ? 'filled' : null,
  }).filter(([_key, value]) => value))

  const renderLink = (children) => {
    const Component = customTemplate === 'gopuff' ? GoPuffLink : LocalizedLink

    return (
      <Component
        className={cx(styles.link, className)}
        href={url}
        target={targetBlank ? '_blank' : null}
      >
        {children}
      </Component>
    )
  }

  return renderLink(
    <ButtonWithTracking
      className={className}
      fullWidth={true}
      {...externalButtonProps}
      {...buttonStyleProps}
      {...{
        analyticsEventData,
        analyticsEventName,
      }}
    >{displayText}</ButtonWithTracking>,
  )
}
